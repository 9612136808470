import React from 'react';

export default function FeaturesSectionFooters11() {
    return (
        <React.Fragment>
            <>
                <section className="pt-16 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="p-8 mb-14 bg-white border border-slate-200 rounded-xl">
      <div className="flex flex-wrap items-center -m-4">
        <div className="w-full md:w-1/2 p-4">
          <div className="md:max-w-lg">
            <h6 className="font-heading mb-3 text-xl font-semibold">Subscribe to our newsletter</h6>
            <p className="text-base text-gray-500 font-medium">Join our newsletter for digital trends and insights.</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <div className="flex flex-wrap lg:justify-end -m-2">
            <div className="w-full lg:w-auto p-2">
              <div className="lg:w-72">
                <input type="text" placeholder="Enter you email address" className="flex w-full px-4 py-3 text-base font-medium disabled:bg-white placeholder-gray-500 disabled:placeholder-gray-300 outline-none border border-lynch-900 active:border-lynch-600 disabled:border-gray-200" />
              </div>
            </div>
            <div className="w-auto p-2">
              <a href="#" className="group relative inline-flex justify-center text-center">
                <span className="relative z-10 inline-flex justify-center px-4 py-3 font-medium text-white border border-lynch-900">Subscribe</span>
                <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="pb-12 border-b border-slate-100">
      <div className="flex flex-wrap -m-8">
        <div className="w-full md:w-1/2 lg:w-4/12 p-8">
          <div className="max-w-xs">
            <div className="mb-6">
              <img src="https://shuffle.dev/placeholder/logos/plain.svg?primary=334155" alt className="h-10" />
            </div>
            <p className="text-base text-gray-500 font-medium">Innovating the digital realm, one project at a time.</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:flex-1 p-8">
          <p className="mb-4 inline-block font-semibold text-sm text-gray-300">Navigation Links</p>
          <ul>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Home</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Features</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Pricing</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">About Us</a></li>
            <li><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Contact Us</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 lg:flex-1 p-8">
          <p className="mb-4 inline-block font-semibold text-sm text-gray-300">
            Product
          </p>
          <ul>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">How it works</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Benefits</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Case studies</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Testimonials</a></li>
            <li><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">FAQs</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 lg:flex-1 p-8">
          <p className="mb-4 inline-block font-semibold text-sm text-gray-300">Resources</p>
          <ul>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Blog</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Whitepapers</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">E-books</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Guides</a></li>
            <li><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Webinars</a></li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 lg:flex-1 p-8">
          <p className="mb-4 inline-block font-semibold text-sm text-gray-300">Social Media</p>
          <ul>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Facebook</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Twitter</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Github</a></li>
            <li className="mb-3"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Instagram</a></li>
            <li><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Linkedin</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="py-8 text-center">
      <p className="text-base text-gray-500 font-medium">© 2023 Plain 2.0. All rights reserved.</p>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

