import React from 'react';

export default function IndexSectionTestimonials15() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 overflow-hidden"><div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-3">
      <div className="w-full md:w-1/2 p-3">
        <div className="py-12 px-12 h-full text-center bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-full">
              <div className="flex flex-wrap justify-center -m-1 mb-12">
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
              </div>
              <div className="mb-12">
                <h4 className="font-heading text-3xl font-semibold">Honestly, one of the most technically talented teams I've worked with across the board.</h4>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2 max-w-max mx-auto">
                <div className="relative inline-flex">
                  <img src="https://media.licdn.com/dms/image/C4E03AQEz47sdgCOLuA/profile-displayphoto-shrink_800_800/0/1599785845713?e=1722470400&v=beta&t=OA-wEwJRl18-iNm7ZQ7oFtF_U6bkCcjR_BB7H9nr1Po" alt="Nish Samantray" className="w-24 h-24 rounded-full object-cover"/>
                </div>
              </div>
              <h6 className="font-heading text-xl font-semibold">Nish Samantray</h6>
              <p className="text-sm text-gray-500 font-medium">
                Founder/CEO at <span className="inline-block font-medium text-sm text-gray-700">Arrae</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-3">
        <div className="py-12 px-12 h-full text-center bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-full">
              <div className="flex flex-wrap justify-center -m-1 mb-12">
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-yellow-400 text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor"/>
                  </svg>
                </div>
              </div>
              <div className="mb-12">
                <h4 className="font-heading text-3xl font-semibold">My secret weapon to grow Customer Lifetime Value.</h4>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2 max-w-max mx-auto">
                <div className="relative inline-flex">
                  <img src="https://media.licdn.com/dms/image/C5603AQHzs4UGlhawow/profile-displayphoto-shrink_800_800/0/1540685737969?e=1722470400&v=beta&t=rzFFbY4P38L3rf4iX4aZ77BwXaJHqZfzQlN5K9BgPmI" alt="David Sanghera" className="w-24 h-24 rounded-full object-cover"/>
                </div>
              </div>
              <h6 className="font-heading text-xl font-semibold">David Sanghera</h6>
              <p className="text-sm text-gray-500 font-medium">Founder at <a href="#" className="inline-block font-medium text-sm text-gray-700 hover:text-gray-900">HerLiaison</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
                </div>
                </section>


            </>
        </React.Fragment>
    );
}

